import { ExternalTeam } from '@index/interfaces';
import { createAction, props, union } from '@ngrx/store';
import { GthTeamModel, GthTeamRoleModel } from '@sentinels/models';

export enum TeamsActionTypes {
  LoadTeamsByUserId = '[Teams] Load Teams By User Id',
  LoadTeamsByUserIdSuccess = '[Teams] Load Teams By User Id Success',
  LoadTeamsByUserIdError = '[Teams] Load Teams By User Id Error',

  LoadExternalTeamsByUserId = '[Teams] Load External Teams By User Id',
  LoadExternalTeamsByUserIdSuccess = '[Teams] Load External Teams By User Id Success',
  LoadExternalTeamsByUserIdError = '[Teams] Load External Teams By User Id Error',

  LoadTeamRoles = '[Teams] Load Team Roles',
  LoadTeamRolesSuccess = '[Teams] Load Team Roles Success',
  LoadTeamRolesError = '[Teams] Load Team Roles Error',

  TeamsLoadListByLatLng = '[Teams] Load List By Lat Lng',
  TeamsLoadListSuccess = '[Teams] Load List Success',
  TeamsLoadListError = '[Teams] Load List Error',

  TeamsClearList = '[Teams] Clear List',
}

export const LoadTeamsByUserId = createAction(
  TeamsActionTypes.LoadTeamsByUserId,
  props<{ userId: string }>(),
);

export const LoadTeamsByUserIdSuccess = createAction(
  TeamsActionTypes.LoadTeamsByUserIdSuccess,
  props<{ teams: GthTeamModel[] }>(),
);

export const LoadTeamsByUserIdError = createAction(
  TeamsActionTypes.LoadTeamsByUserIdError,
  props<{ error: unknown }>(),
);

export const LoadExternalTeamsByUserId = createAction(
  TeamsActionTypes.LoadExternalTeamsByUserId,
  props<{ userId: string }>(),
);

export const LoadExternalTeamsByUserIdSuccess = createAction(
  TeamsActionTypes.LoadExternalTeamsByUserIdSuccess,
  props<{ externalTeams: ExternalTeam[] }>(),
);

export const LoadExternalTeamsByUserIdError = createAction(
  TeamsActionTypes.LoadExternalTeamsByUserIdError,
  props<{ error: unknown }>(),
);

export const LoadTeamRoles = createAction(
  TeamsActionTypes.LoadTeamRoles,
);

export const LoadTeamRolesSuccess = createAction(
  TeamsActionTypes.LoadTeamRolesSuccess,
  props<{ roles: GthTeamRoleModel[] }>(),
);

export const LoadTeamRolesError = createAction(
  TeamsActionTypes.LoadTeamRolesError,
  props<{ error: unknown }>(),
);

export const loadTeamsListByLatLng = createAction(
  TeamsActionTypes.TeamsLoadListByLatLng,
  props<{ lat: number, lng: number }>(),
);

export const LoadTeamsListSuccess = createAction(
  TeamsActionTypes.TeamsLoadListSuccess,
  props<{
      teams: GthTeamModel[],
  }>(),
);

export const LoadTeamsListError = createAction(
  TeamsActionTypes.TeamsLoadListError,
  props<{ error: unknown }>(),
);

export const ClearTeamsList = createAction(
  TeamsActionTypes.TeamsClearList,
);

export const allTeams = union({
  LoadTeamsListSuccess,
  LoadTeamsListError,
});

export type TeamsActionsUnion = typeof allTeams;
